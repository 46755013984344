import { useToastState } from "@react-stately/toast";
import { isNewTheme } from "core/model/utils/featureFlags";
import {
  NotificationContentType,
  ToastContentType,
  ToastNotificationRegion,
} from "ds/ui";
import { useNotification as useNotificationOld } from "dsl/organisms/NotificationProvider";
import { ReactNode, createContext, useCallback, useContext } from "react";
import { useNotificationState } from "./NotificationReducer";

const TOAST_TIMEOUT = 5000;

const NotificationContext = createContext<{
  notify: (options: NotificationContentType) => void;
  toast: (options: ToastContentType) => void;
}>({ toast: () => {}, notify: () => {} });

export function useNotification() {
  return useContext(NotificationContext).notify;
}

export function useToastContext() {
  return useContext(NotificationContext).toast;
}

export function useToast() {
  const notify = useNotificationOld();
  const toast = useToastContext();

  return (options: ToastContentType) => {
    if (isNewTheme) {
      return toast(options);
    }

    return notify({
      type:
        options.color === "danger"
          ? "error"
          : options.color === "success"
          ? "success"
          : undefined,
      message: options.message,
      ...(options.action && {
        actions: [
          {
            label: options.action.label,
            onClick: options.action.onClick || (() => {}),
          },
        ],
      }),
    });
  };
}

const MAX_VISIBLE_NOTIFICATIONS = 3;
const MAX_VISIBLE_TOASTS = 5;

export function ToastNotificationProvider({
  children,
}: {
  children: ReactNode;
}) {
  const notificationState = useNotificationState({
    maxVisibleToasts: MAX_VISIBLE_NOTIFICATIONS,
  });
  const toastState = useToastState<ToastContentType>({
    maxVisibleToasts: MAX_VISIBLE_TOASTS,
  });

  const showToast = useCallback(
    (payload: ToastContentType) =>
      toastState.add(payload, { timeout: TOAST_TIMEOUT }),
    [toastState.add],
  );

  const showNotification = useCallback(
    (payload: NotificationContentType) => notificationState.add(payload),
    [notificationState.add],
  );

  const haveNotifications =
    notificationState.visibleToasts.length > 0 ||
    toastState.visibleToasts.length > 0;

  return (
    <NotificationContext.Provider
      value={{ notify: showNotification, toast: showToast }}
    >
      {children}
      {haveNotifications && (
        <ToastNotificationRegion
          notificationState={notificationState}
          toastState={toastState}
        />
      )}
    </NotificationContext.Provider>
  );
}
